<template>
  <div class="p-3">
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-25 mb-6" v-for="i in 16" :key="i">
        <md-card class="flat">
          <md-card-area>
            <md-card-media class="text-center p-4">
              <img
               style="width: 50%"
                src="https://www.vectorico.com/wp-content/uploads/2019/01/folder-icon-300x300.png"
                alt="People"
              />
            </md-card-media>

            <md-card-header>
              <div class="md-title text-center">File name</div>
            </md-card-header>
          </md-card-area>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Student-Material-Directory-View",
};
</script>

